import React, { useEffect, useState } from 'react'
import './App.css'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Font } from "@react-pdf/renderer"
import Navbar from './components/Navbar'
import Invoice from './components/Invoice/Invoice'
import Waybill from './components/Waybill/Waybill'
import LoginPage from './components/LoginPage'
import axios from 'axios'
import Notif from './components/Notif'
import { useGlobal } from './GlobalProvider'
import Mailing from './components/Mailing/Mailing'
import { config } from './config'
import Home from './components/Home'

axios.defaults.withCredentials = true

Font.register({
  family: 'Arial',
  fonts: [
      { src: require("./fonts/Arial-Regular.ttf") },
      { src: require("./fonts/Arial-Medium.ttf"), fontWeight: 500 },
      { src: require("./fonts/Arial-Bold.ttf"), fontWeight: 700 },
      { src: require("./fonts/Arial-Italic.ttf"), fontStyle: "italic" },
      { src: require("./fonts/Arial-Medium-Italic.ttf"), fontWeight: 500, fontStyle: "italic" },
      { src: require("./fonts/Arial-Bold-Italic.ttf"), fontWeight: 700, fontStyle: "italic" }
  ]
})

function App() {
  const [global, setGlobal] = useGlobal()
  const [loading, setLoading] = useState(true)

  const checkIsLogin = async () => {
    await axios.get(`${config.API_HOST}/admin/islogin`)
      .then(res => {
        setGlobal({
          ...global,
          user: res.data.user
        })
      })
      .catch(err => {
        if(err.response){
          setGlobal({
            ...global,
            user: err.response.data.user
          })
        } else {
          console.error(`${err.message}!`)
        }
      })

    if(loading){
      setLoading(false)
    }
  }

  useEffect(() => {
    checkIsLogin()
    
    const interval = setInterval(checkIsLogin, 60 * 60 * 1000)
    return () => clearInterval(interval)
  }, [])

  if(global.user && loading === false){
    return(
      <div id="App">
        <Navbar />
        <Notif />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/add/invoice' element={<Invoice />} />
          <Route path='/add/mailing' element={<Mailing />} />
          <Route path='/add/waybill' element={<Waybill />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </div>
    )
  } else if(!global.user && loading === false) {
    return(
      <div>
        <Notif />
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </div>
    )
  } else {
    return(
      <></>
    )
  }
}

export default App
