import React from 'react'

const WaybillProducts = (props) => {
    const handleChange = (e) => {
        props.el[e.target.name] = e.target.value
        props.el.netWeight = `${(parseInt(props.el.grossWeight?.replace(/[^0-9]/g, '')) - parseInt(props.el.tareWeight?.replace(/[^0-9]/g, ''))).toLocaleString('id-ID')} kg`

        props.setData({
            ...props.data,
            products: [...props.data.products]
        })
    }

    const handleDelete = () => {
        const updatedProducts = props.data.products
        updatedProducts.splice(props.index, 1)
        
        props.setData({
            ...props.data,
            products: updatedProducts
        })
    }

    return (
        <tr>
            <td> <input type="text" name="name" onChange={handleChange} value={props.el.name} autoComplete="off" /> </td>
            <td> <input type="text" name="grossWeight" onChange={handleChange} value={props.el.grossWeight} autoComplete="off" /> </td>
            <td> <input type="text" name="tareWeight" onChange={handleChange} value={props.el.tareWeight} autoComplete="off" /> </td>
            <td> {props.el.netWeight} </td>
            <td> <button disabled={props.data.products.length === 1 ? true : false} onClick={handleDelete}>x</button> </td>
        </tr>
    )
}

export default WaybillProducts
