import React, { useEffect, useState } from 'react'
import Viewer from '../Viewer';
import MailingForm from './MailingForm';
import MailingDoc from './MailingDoc';
import axios from 'axios';
import { useGlobal } from '../../GlobalProvider';
import { config } from '../../config';

const Mailing = () => {
    const [global, setGlobal] = useGlobal()
    const [loading, setLoading] = useState(true)

    const [data, setData] = useState({
        no: 1,
        selectedMailType: null,
        tax: 11,
        date: new Date(),
        selectedSignature: null,
        products: [{
            name: "",
            netWeight: "",
            unitPrice: "",
            amount: ""
        }],
        selectedCompany: null,
        subject: '',
        consigneeText: '',
        mainText: '',
        archives: []
    })

    const req = async () => {
        await axios.get(`${config.API_HOST}/admin/mailings`)
            .then(res => {
                setData(d => { return {
                    ...d,
                    mailings: res.data.data,
                    no: parseInt(res.data.data.length !== 0 ? res.data.data[res.data.data.length-1].no.slice(-4) : 0, 10) + 1
                }})
            })
            .catch(err => {
                if(err.response){
                    if(err.response.status === 403){
                        setGlobal({
                            ...global,
                            user: null,
                            notif: {
                                warning: true,
                                message: err.response.data.message
                            }
                        })
                    } else {
                        setGlobal({
                            ...global,
                            notif: {
                                warning: true,
                                message: err.response.data.message
                            }
                        })
                    } 
                } else {
                    setGlobal({
                        ...global,
                        notif: {
                            warning: true,
                            message: `${err.message}!`
                        }
                    })
                }
            })

        await axios.get(`${config.API_HOST}/admin/companies`)
            .then(res => {
                setData(d => { return {
                    ...d,
                    companies: res.data.data,
                    myCompany: res.data.data[0]
                }})
            })
            .catch(err => {
                if(err.response){
                    if(err.response.status === 403){
                        setGlobal({
                            ...global,
                            user: null,
                            notif: {
                                warning: true,
                                message: err.response.data.message
                            }
                        })
                    } else {
                        setGlobal({
                            ...global,
                            notif: {
                                warning: true,
                                message: err.response.data.message
                            }
                        })
                    } 
                } else {
                    setGlobal({
                        ...global,
                        notif: {
                            warning: true,
                            message: `${err.message}!`
                        }
                    })
                }
            })
        
        await axios.get(`${config.API_HOST}/admin/mail_types`)
            .then(res => {
                setData(d => { return {
                    ...d,
                    mailTypes: res.data.data
                }})
            })
            .catch(err => {
                if(err.response){
                    if(err.response.status === 403){
                        setGlobal({
                            ...global,
                            user: null,
                            notif: {
                                warning: true,
                                message: err.response.data.message
                            }
                        })
                    } else {
                        setGlobal({
                            ...global,
                            notif: {
                                warning: true,
                                message: err.response.data.message
                            }
                        })
                    } 
                } else {
                    setGlobal({
                        ...global,
                        notif: {
                            warning: true,
                            message: `${err.message}!`
                        }
                    })
                }
            })

        await axios.get(`${config.API_HOST}/admin/signatures`)
            .then(res => {
                setData(d => { return {
                    ...d,
                    signatures: res.data.data
                }})
            })
            .catch(err => {
                if(err.response){
                    if(err.response.status === 403){
                        setGlobal({
                            ...global,
                            user: null,
                            notif: {
                                warning: true,
                                message: err.response.data.message
                            }
                        })
                    } else {
                        setGlobal({
                            ...global,
                            notif: {
                                warning: true,
                                message: err.response.data.message
                            }
                        })
                    } 
                } else {
                    setGlobal({
                        ...global,
                        notif: {
                            warning: true,
                            message: `${err.message}!`
                        }
                    })
                }
            })
        
        if(loading){
            setLoading(false)
        }
    }

    useEffect(() => {
        req()
    }, [])

    if(!loading){
        return (
            <div id='mailing'>
                <MailingForm data={data} setData={setData} />
                <Viewer doc={<MailingDoc data={data} />} />
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}

export default Mailing
