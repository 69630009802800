import React from 'react'
import axios from 'axios'
import { useGlobal } from '../../GlobalProvider'
import { arabToRoman } from 'roman-numbers'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { config } from '../../config'

const MailingForm = (props) => {
    const [global, setGlobal] = useGlobal()

    const handleChange = async (e) => {
        if(e.target.name === 'date') {
            props.setData({
                ...props.data,
                [e.target.name]: new Date(e.target.value)
            })
        } else if(e.target.name === 'selectedConsigneeCompany' || e.target.name === 'selectedShipperCompany'|| e.target.name === 'selectedSignature') {
            props.setData({
                ...props.data,
                [e.target.name]: JSON.parse(e.target.value)
            })
        } else if(e.target.name === 'selectedMailType') {
            const value = await JSON.parse(e.target.value)

            await props.setData(data => ({
                ...data,
                selectedMailType: value
            }))

            const template = await JSON.parse(value.template)

            await props.setData(data => ({
                ...data,
                subject: template.subject
            }))
            await props.setData(data => ({
                ...data,
                consigneeText: template.consigneeText
            }))
            await props.setData(data => ({
                ...data,
                mainText: template.mainText
            }))
        } else if(e.target.name === 'archives') {
            const availableType = ["image/jpeg", "image/jpg", "image/png", "application/pdf"]

            if(e.target.files[0].type && availableType.includes(e.target.files[0].type)){
                props.setData({
                    ...props.data,
                    [e.target.name]: [...props.data.archives, e.target.files[0]]
                })
                e.target.style = ""
            } else {
                e.target.style.border = "1px solid red"
            }
        } else {
            props.setData({
                ...props.data,
                [e.target.name]: e.target.value
            })
        }
    }

    const upload = (formData) => {
        axios.post(`${config.API_HOST}/admin/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(res => {
                setGlobal({
                    ...global,
                    notif: {
                        warning: false,
                        message: res.data.message
                    }
                })
            })
            .catch(err => {
                if(err.response){
                    if(err.response.status === 403){
                        setGlobal({
                            ...global,
                            user: null,
                            notif: {
                                warning: true,
                                message: err.response.data.message
                            }
                        })
                    } else {
                        setGlobal({
                            ...global,
                            notif: {
                                warning: true,
                                message: err.response.data.message
                            }
                        })
                    } 
                } else {
                    setGlobal({
                        ...global,
                        notif: {
                            warning: true,
                            message: `${err.message}!`
                        }
                    })
                }
            })
    }

    const handleSubmit = () => {
        const content = document.querySelector('iframe')

        const fileName = `${props.data.selectedMailType ? props.data.selectedMailType.code 
            : "xx"}_${props.data.selectedShipperCompany ? props.data.selectedShipperCompany.code 
            : "xxx"}_${props.data.date.getFullYear().toString()}_${arabToRoman(props.data.date.getMonth() + 1)}_${props.data.no.toString().padStart(4, '0')}`

        fetch(content.src)
            .then(response => response.blob())
            .then(blob => {
                const file = new File([blob], 
                    `${fileName}.pdf`,
                    { type: 'application/pdf' })

                const formData = new FormData()
                formData.append('file', file)

                axios.post(`${config.API_HOST}/admin/mailings/add`, {
                    no: fileName,
                    mail_type: props.data.selectedMailType ? props.data.selectedMailType.name : '',
                    shipper_company: props.data.selectedShipperCompany ? props.data.selectedShipperCompany.name : '',
                    consignee_company: props.data.selectedConsigneeCompany ? props.data.selectedConsigneeCompany.name : '',
                    subject: props.data.subject,
                    date: props.data.date.toISOString(),
                    signature: props.data.selectedSignature ? props.data.selectedSignature.name : '',
                })
                    .then(res => {
                        setGlobal({
                            ...global,
                            notif: {
                                warning: false,
                                message: res.data.message
                            }
                        })

                        upload(formData)
                        
                        if(global.isMobile){
                            const link = document.createElement('a')
                            link.href = content.src
                            link.setAttribute('download', file.name)

                            document.body.appendChild(link)
                            link.click()

                            document.body.removeChild(link)
                        } else {
                            content.contentWindow.print()
                        }
                    })
                    .catch(err => {
                        if(err.response){
                            if(err.response.status === 403){
                                setGlobal({
                                    ...global,
                                    user: null,
                                    notif: {
                                        warning: true,
                                        message: err.response.data.message
                                    }
                                })
                            } else {
                                setGlobal({
                                    ...global,
                                    notif: {
                                        warning: true,
                                        message: err.response.data.message
                                    }
                                })
                            } 
                        } else {
                            setGlobal({
                                ...global,
                                notif: {
                                    warning: true,
                                    message: `${err.message}!`
                                }
                            })
                        }
                    })

                
            }) 
    }

    const handleUpdate = async () => {
        await axios.put(`${config.API_HOST}/admin/mail_types/update/${props.data.selectedMailType ? props.data.selectedMailType.code : 'xx'}`, {
            code: props.data.selectedMailType ? props.data.selectedMailType.code : '',
            name: props.data.selectedMailType ? props.data.selectedMailType.name : '',
            template: JSON.stringify({
                subject: props.data.subject,
                consigneeText: props.data.consigneeText,
                mainText: props.data.mainText
            })
        })
            .then(res => {
                setGlobal({
                    ...global,
                    notif: {
                        warning: false,
                        message: res.data.message
                    }
                })
            })
            .catch(err => {
                if(err.response){
                    if(err.response.status === 403){
                        setGlobal({
                            ...global,
                            user: null,
                            notif: {
                                warning: true,
                                message: err.response.data.message
                            }
                        })
                    } else {
                        setGlobal({
                            ...global,
                            notif: {
                                warning: true,
                                message: err.response.data.message
                            }
                        })
                    } 
                } else {
                    setGlobal({
                        ...global,
                        notif: {
                            warning: true,
                            message: `${err.message}!`
                        }
                    })
                }
            })
    }

    return (
        <div id='mailing-form'>
            <h2> Mailing </h2>
            <table style={{ width: "650px" }}>
                <tbody>
                    <tr>
                        <td style={{width: "40%"}}> <span> No </span> </td>
                        <td> <input type="text" defaultValue={props.data.no} style={{ width: 40, textAlign: "right" }} onChange={handleChange} name="no" autoComplete="off" 
                                pattern="[0-9]*" 
                                inputMode="numeric" 
                                maxLength="4" /> </td>
                    </tr>
                    <tr>
                        <td> <label> Date </label> </td>
                        <td> <input type="date" defaultValue={props.data.date.toISOString().slice(0, 10)} style={{ textAlign: "right" }} onChange={handleChange} name="date" /> </td>
                    </tr>
                    <tr>
                        <td> <label> Jenis Surat </label> </td>
                        <td>
                            <select defaultValue={""} onChange={handleChange} name="selectedMailType" style={{ width: "100%" }}>
                                <option style={{ textAlign: 'center' }} disabled value={""}> -- Silahkan Pilih -- </option>
                                {props.data.mailTypes.map((el, index) => 
                                    <option key={index} value={JSON.stringify(el)}>{el.name}</option>
                                )}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td> <span> Perihal </span> </td>
                        <td> <input type="text" style={{ width: '100%' }} value={props.data.subject} onChange={handleChange} name="subject" autoComplete="off" /> </td>
                    </tr>
                    <tr>
                        <td> <label>Perusahaan Shipper</label> </td>
                        <td>
                            <select defaultValue={""} onChange={handleChange} name="selectedShipperCompany" style={{ width: "100%" }}>
                                <option style={{ textAlign: 'center' }} disabled value={""}> -- Silahkan Pilih -- </option>
                                {props.data.companies.map((el, index) => {
                                    if(el.name.includes('GOMAS')){
                                        return <option key={index} value={JSON.stringify(el)}>{el.name}</option>
                                    }
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td> <label>Perusahaan Consignee</label> </td>
                        <td>
                            <select defaultValue={""} onChange={handleChange} name="selectedConsigneeCompany" style={{ width: "100%" }}>
                                <option style={{ textAlign: 'center' }} disabled value={""}> -- Silahkan Pilih -- </option>
                                {props.data.companies.map((el, index) => {
                                    if(!el.name.includes('GOMAS')){
                                        return <option key={index} value={JSON.stringify(el)}>{el.name}</option>
                                    }
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td> <label> Yang Menandatangani </label> </td>
                        <td>
                            <select defaultValue={JSON.stringify(null)} onChange={handleChange} name="selectedSignature" style={{ width: "100%" }}>
                                <option style={{ textAlign: 'center' }} disabled value={JSON.stringify(null)}> -- Silahkan Pilih -- </option>
                                {props.data.signatures.map((el, index) => 
                                    <option key={index} value={JSON.stringify(el)}>{el.name}</option>
                                )}
                            </select>
                        </td>
                    </tr>

                    <tr><td><br/><span>Tujuan Surat</span></td></tr>

                    <tr>
                        <td colSpan={2}>
                            <ReactQuill style={{ width: "650px" }}
                                modules={{
                                    toolbar: {
                                        container: [
                                            ['bold', 'italic', 'underline','strike'],
                                            [{'list': 'ordered'}, {'list': 'bullet'}],
                                        ]
                                    }
                                }}
                                formats={[
                                    'bold', 'italic', 'underline', 'strike',
                                    'list', 'bullet'
                                ]}
                                value={props.data.consigneeText}
                                onChange={(value, delta, source, editor) => {
                                    props.setData({
                                        ...props.data,
                                        consigneeText: editor.getContents()
                                    })
                                }}
                            />
                        </td>
                    </tr>

                    <tr><td><br/><span>Isi Surat</span></td></tr>

                    <tr>
                        <td colSpan={2}>
                            <ReactQuill style={{ width: "650px" }}
                                modules={{
                                    toolbar: {
                                        container: [
                                            ['bold', 'italic', 'underline','strike'],
                                            [{'list': 'ordered'}, {'list': 'bullet'}],
                                        ]
                                    }
                                }}
                                formats={[
                                    'bold', 'italic', 'underline', 'strike',
                                    'list', 'bullet'
                                ]}
                                value={props.data.mainText}
                                onChange={(value, delta, source, editor) => {
                                    props.setData({
                                        ...props.data,
                                        mainText: editor.getContents()
                                    })
                                }}
                            />
                        </td>
                    </tr>

                    <tr><td><br/><span>Lampiran</span></td></tr>

                    {Array.from({ length: props.data.archives.length+1 }).map((el, index) => 
                        <tr key={`tr-archives-${index}`}>
                            <td key={`td1-archives-${index}`}>
                                <input key={`input-archives-${index}`} type='file' onChange={handleChange} name='archives' accept=".pdf, .jpeg, .jpg, .png" />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            <br/>

            <button style={{ width: 100, height: 36 }} onClick={handleSubmit}> Upload </button>

            <br/>

            <button style={{ width: 100, height: 36 }} onClick={handleUpdate}> Update Template </button>
        </div>
    )
}

export default MailingForm
