import React from 'react'
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { arabToRoman } from 'roman-numbers'

const styles = StyleSheet.create({
    page: {
        padding: 37.44,
        height: "100%",
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderBottom: "1.2px solid rgb(220, 220, 220)"
    },
    title: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
        marginBottom: 12,
        width: "100%"
    },
    logo: {
        objectFit: "cover",
        aspectRatio: "1 / 1",
        marginLeft: 6,
        width: 64,
        height: "auto"
    },
    headerText: {
        width: "100%",
        textAlign: "right",
        display: "flex",
        flexDirection: "column",
        fontSize: 11,
        marginTop: -10
    },
    main: {
        marginTop: 40,
        fontSize: 11,
        textAlign: 'justify',
        lineHeight: 1.5
    },
    sign: {
        marginTop: 80,
        fontSize: 11
    }
});

const NewsPage = (props) => {
    return(
        <Page size="A4" style={{ fontFamily: "Arial" }}>
            <View style={styles.page}>
                <View style={styles.header}>
                    <View style={styles.title}>
                        <Image src={require("../../images/Logo.png")} style={styles.logo} />
                        <Text style={{ fontWeight: 700, fontSize: 24 }}>Berita Acara</Text>
                    </View>

                    <View style={styles.headerText}>
                        <Text style={{ fontWeight: 700, fontSize: 14, paddingBottom: 4 }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.name.replace('.', '') : "-"}</Text>
                        <Text>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.long_address.split(', ').slice(0, 3).join(', ') : "-"}</Text>
                        <Text>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.long_address.split(', ').slice(3).join(', ') : "-"}</Text>
                        <Text style={{ color: "blue", textDecoration: "underline" }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.email : "-"}</Text>
                        <Text>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.phone : "-"}</Text>
                    </View>
                </View>

                <View style={styles.main}>
                    <Text>{`Sehubungan dengan rencana pemuatan Cangkang Sawit sesuai dengan surat jalan nomor WB/${props.data.selectedDivision ? props.data.selectedDivision.code 
                            : "xxx"}/${props.data.date.getFullYear().toString()}/${arabToRoman(props.data.date.getMonth() + 1)}/${props.data.no.toString().padStart(4, '0')} dari pabrik kelapa sawit ${props.data.pks}, ${props.data.location}, kami sampaikan bahwa pajak kendaraan yang akan mengangkut Cangkang Sawit sedang mati dan dalam pengurusan.`}</Text>
                    
                    <Text style={{ marginTop: 10 }}>Adapun unit tersebut:</Text>
                    <table>
                        <tr style={{ display: "flex", flexDirection: "row", marginLeft: 20 }}>
                            <td style={{ width: "20%" }}><Text>Nama Supir</Text></td>
                            <td><Text>: </Text></td>
                            <td><Text>{props.data.driverName ? props.data.driverName : "-"}</Text></td>
                        </tr>
                        <tr style={{ display: "flex", flexDirection: "row", marginLeft: 20 }}>
                            <td style={{ width: "20%" }}><Text>No Polisi</Text></td>
                            <td><Text>: </Text></td>
                            <td><Text>{props.data.plateNumber ? props.data.plateNumber : "-"}</Text></td>
                        </tr>
                    </table>
                    <Text style={{ marginTop: 10 }}>Demikian berita acara ini kami buat untuk dipergunakan sebagaimana mestinya.</Text>
                </View>

                <View style={styles.sign}>
                    <table style={{ width: "100%" }}>
                        <tr style={{ display: "flex", flexDirection: "row" }}>
                            <td style={{ width: "50%" }}> </td>
                            <td style={{ width: "50%", justifyContent: "center", textAlign: "center", marginTop: 4, gap: 4 }}>
                                <Text>{`Makassar, ${props.data.date.toLocaleDateString("id-ID", {year: "numeric", month: "long", day:"numeric"})}`}</Text>
                                <Text>Hormat kami,</Text>
                                <Text> </Text>
                                <Text> </Text>
                                <Text> </Text>
                                <Text> </Text>
                                <Text>{props.data.signature ? props.data.signature : "____________________"}</Text>
                            </td>
                        </tr>
                    </table>
                </View>
            </View>
        </Page>
    )
}

export default NewsPage
