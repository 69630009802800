import React from 'react'
import { useGlobal } from '../GlobalProvider'
import CloseIcon from '@mui/icons-material/Close';

const Notif = () => {
    const [global, setGlobal] = useGlobal()

    const handleClose = () => {
        setGlobal({
            ...global,
            notif: null
        })
    }

    if(global.notif){
        return(
            <div id='notif' style={{ backgroundColor: global.notif.warning ? "rgba(255, 72, 72, 0.95)" : "rgba(109, 255, 72, 0.95)" }}>
                <span className='notif-message'>{global.notif.message}</span>
                <span className='notif-close' onClick={handleClose}><CloseIcon fontSize='small'/></span>
            </div>
        )
    } else {
        return(
            <></>
        )
    }
}

export default Notif
