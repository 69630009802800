import React from 'react'

const InvoiceProducts = (props) => {
    const handleChange = (e) => {
        props.el[e.target.name] = e.target.value
        props.el.amount = (parseInt(props.el.netWeight.replace(/[^0-9]/g, '')) * parseInt(props.el.unitPrice.replace(/[^0-9]/g, ''))).toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR"
        }).replace(',00', '').replace(/\s+/g, "")

        props.setData({
            ...props.data,
            products: [...props.data.products]
        })
    }

    const handleDelete = () => {
        const updatedProducts = props.data.products
        updatedProducts.splice(props.index, 1)
        
        props.setData({
            ...props.data,
            products: updatedProducts
        })
    }

    return (
        <tr>
            <td> <input type="text" name="name" onChange={handleChange} value={props.el.name} autoComplete="off" /> </td>
            <td> <input type="text" name="netWeight" onChange={handleChange} value={props.el.netWeight} autoComplete="off" /> </td>
            <td> <input type="text" name="unitPrice" onChange={handleChange} value={props.el.unitPrice} autoComplete="off" /> </td>
            <td> <button disabled={props.data.products.length === 1 ? true : false} onClick={handleDelete}>x</button> </td>
        </tr>
    )
}

export default InvoiceProducts
