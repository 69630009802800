import React from 'react'
import InvoiceProducts from './InvoiceProducts'
import axios from 'axios'
import { useGlobal } from '../../GlobalProvider'
import { arabToRoman } from 'roman-numbers'
import { config } from '../../config'
import { Checkbox, FormControlLabel } from '@mui/material'
import WaybillList from './WaybillList'

const InvoiceForm = (props) => {
    const [global, setGlobal] = useGlobal()

    const handleChange = (e) => {
        if(e.target.name === 'date') {
            props.setData({
                ...props.data,
                [e.target.name]: new Date(e.target.value)
            })
        } else if(e.target.name === 'selectedConsigneeCompany' ||
                    e.target.name === 'selectedSignature') {
            props.setData({
                ...props.data,
                [e.target.name]: JSON.parse(e.target.value)
            })
        } else if(e.target.name === 'selectedWaybill') {
            if(!JSON.stringify(props.data.selectedWaybill).includes(e.target.value)) {
                const array = [...props.data.selectedWaybill, JSON.parse(e.target.value)].sort((a , b) => a.id - b.id)
                
                props.setData({
                    ...props.data,
                    [e.target.name]: array
                })
            }
        } else if(e.target.name === 'selectedShipperCompany') {
            const filterCodeResults = props.data.invoices.map(el => el.no).filter(el => el.includes(JSON.parse(e.target.value).code))
            const filterYearResults = filterCodeResults.map(el => el.split('_')).filter(el => el[2] === new Date().getFullYear().toString())
            const filterNumberResults = filterYearResults.map(el => +el[el.length-1])
            const maxNumber = Math.max(...filterNumberResults)

            props.setData(d => {return {
                ...d,
                [e.target.name]: JSON.parse(e.target.value),
                no: maxNumber+1
            }})
        } else if(e.target.name === 'withTax') {
            props.setData({
                ...props.data,
                [e.target.name]: e.target.checked
            })    
        } else {
            props.setData({
                ...props.data,
                [e.target.name]: e.target.value
            })
        }
    }

    const handleAdd = () => {
        props.setData({
            ...props.data,
            products: [...props.data.products, {
                name: "",
                netWeight: "",
                unitPrice: "",
                amount: ""
            }]
        })
    }

    const upload = async (formData) => {
        axios.post(`${config.API_HOST}/admin/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(res => {
                setGlobal({
                    ...global,
                    notif: {
                        warning: false,
                        message: res.data.message
                    }
                })
            })
            .catch(err => {
                if(err.response){
                    if(err.response.status === 403){
                        setGlobal({
                            ...global,
                            user: null,
                            notif: {
                                warning: true,
                                message: err.response.data.message
                            }
                        })
                    } else {
                        setGlobal({
                            ...global,
                            notif: {
                                warning: true,
                                message: err.response.data.message
                            }
                        })
                    } 
                } else {
                    setGlobal({
                        ...global,
                        notif: {
                            warning: true,
                            message: `${err.message}!`
                        }
                    })
                }
            })
    }

    const handleSubmit = () => {
        const content = document.querySelector('iframe')

        const fileName = `CI_${props.data.selectedShipperCompany ? props.data.selectedShipperCompany.code 
            : "xxx"}_${props.data.date.getFullYear().toString()}_${arabToRoman(props.data.date.getMonth() + 1)}_${props.data.no.toString().padStart(4, '0')}`

        fetch(content.src)
            .then(response => response.blob())
            .then(blob => {
                const file = new File([blob], 
                    `${fileName}.pdf`,
                    { type: 'application/pdf' })

                const formData = new FormData()
                formData.append('file', file)

                axios.post(`${config.API_HOST}/admin/invoices/add`, {
                    no: fileName,
                    waybill_no: props.data.selectedWaybill ? props.data.selectedWaybill.map(el => el.no).join(', ') : '',
                    shipper_company: props.data.selectedShipperCompany ? props.data.selectedShipperCompany.name : '',
                    consignee_company: props.data.selectedConsigneeCompany ? props.data.selectedConsigneeCompany.name : '',
                    products: JSON.stringify(props.data.products),
                    amount: props.data.subTotal ? props.data.subTotal : '',
                    tax: props.data.taxAmount || props.data.taxAmount === 0 ? props.data.taxAmount : '',
                    date: props.data.date.toISOString(),
                    signature: props.data.selectedSignature ? props.data.selectedSignature.name : '',
                })
                    .then(res => {
                        setGlobal({
                            ...global,
                            notif: {
                                warning: false,
                                message: res.data.message
                            }
                        })

                        upload(formData)
                            .then(res => {
                                if(global.isMobile){
                                    const link = document.createElement('a')
                                    link.href = content.src
                                    link.setAttribute('download', file.name)
        
                                    document.body.appendChild(link)
                                    link.click()
        
                                    document.body.removeChild(link)
                                } else {
                                    content.contentWindow.print()
                                }
                            })
                    })
                    .catch(err => {
                        if(err.response){
                            if(err.response.status === 403){
                                setGlobal({
                                    ...global,
                                    user: null,
                                    notif: {
                                        warning: true,
                                        message: err.response.data.message
                                    }
                                })
                            } else {
                                setGlobal({
                                    ...global,
                                    notif: {
                                        warning: true,
                                        message: err.response.data.message
                                    }
                                })
                            } 
                        } else {
                            setGlobal({
                                ...global,
                                notif: {
                                    warning: true,
                                    message: `${err.message}!`
                                }
                            })
                        }
                    })

                
            })
    }

    return (
        <div id='invoice-form'>
            <h2> Invoice </h2>
            <table style={{ width: "60%" }}>
                <tbody>
                    <tr>
                        <td style={{width: "40%"}}> <label> No </label> </td>
                        <td> <input type="text" value={props.data.no ? props.data.no : 1} style={{ width: 40, textAlign: "right" }} onChange={handleChange} name="no" autoComplete="off" 
                                pattern="[0-9]*" 
                                inputMode="numeric" 
                                maxLength="4" /> </td>
                    </tr>
                    <tr>
                        <td> <label> Date </label> </td>
                        <td> <input type="date" defaultValue={props.data.date.toISOString().slice(0, 10)} style={{ textAlign: "right" }} onChange={handleChange} name="date" /> </td>
                    </tr>
                    <tr>
                        <td> <label> Nomor PO </label> </td>
                        <td> <input type="text" defaultValue={props.data.po} style={{ width: "100%" }} onChange={handleChange} name="po" /> </td>
                    </tr>
                    <tr>
                        <td> <label> No Surat Jalan (Waybill) </label> </td>
                        <td>
                            <select defaultValue={""} onChange={handleChange} name="selectedWaybill" style={{ width: "100%" }}>
                                <option style={{ textAlign: 'center' }} disabled value={""}> -- Silahkan Pilih -- </option>
                                {props.data.waybills.sort((a, b) => b.id - a.id).map((el, index, arr) =>{ 
                                    const date = new Date(el.date)
                                    if(index !== 0){
                                        const prevDate = new Date(arr[index-1].date)
                                        if(date.toLocaleDateString("id-ID", {year: "numeric", month: "long", day:"numeric"}) !== prevDate.toLocaleDateString("id-ID", {year: "numeric", month: "long", day:"numeric"})){
                                            return(
                                                <React.Fragment key={index}>
                                                    <option style={{ textAlign: 'center' }} key={`hint-${index}`} disabled>{`----${date.toLocaleDateString("id-ID", {year: "numeric", month: "long", day:"numeric"})}----`}</option>
                                                    <option key={index} value={JSON.stringify(el)}>{`${el.no.replace(/_/g, '/')} - ${el.plate_number} - ${el.driver}`}</option>
                                                </React.Fragment>
                                            )
                                        }
                                    } else {
                                            return(
                                                <React.Fragment key={index}>
                                                    <option style={{ textAlign: 'center' }} key={`hint-${index}`} disabled>{`----${date.toLocaleDateString("id-ID", {year: "numeric", month: "long", day:"numeric"})}----`}</option>
                                                    <option key={index} value={JSON.stringify(el)}>{`${el.no.replace(/_/g, '/')} - ${el.plate_number} - ${el.driver}`}</option>
                                                </React.Fragment>
                                            )
                                    }
                                    return <option key={index} value={JSON.stringify(el)}>{`${el.no.replace(/_/g, '/')} - ${el.plate_number} - ${el.driver}`}</option>
                                })}
                            </select>
                        </td>
                    </tr>
                    
                    {props.data.selectedWaybill.length ? props.data.selectedWaybill.map((el, index) => 
                    <WaybillList key={index} data={props.data} setData={props.setData} el={el} index={index} />) : ''}
                    
                    <tr>
                        <td> <label> Perusahaan Shipper </label> </td>
                        <td>
                            <select defaultValue={""} onChange={handleChange} name="selectedShipperCompany" style={{ width: "100%" }}>
                                <option style={{ textAlign: 'center' }} disabled value={""}> -- Silahkan Pilih -- </option>
                                {props.data.companies.map((el, index) => {
                                    if(el.name.includes('GOMAS')){
                                        return <option key={index} value={JSON.stringify(el)}>{el.name}</option>
                                    }
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td> <label> Perusahaan Consignee </label> </td>
                        <td>
                            <select defaultValue={""} onChange={handleChange} name="selectedConsigneeCompany" style={{ width: "100%" }}>
                                <option style={{ textAlign: 'center' }} disabled value={""}> -- Silahkan Pilih -- </option>
                                {props.data.companies.map((el, index) => 
                                    <option key={index} value={JSON.stringify(el)}>{el.name}</option>
                                )}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td> <label> Yang Menandatangani </label> </td>
                        <td>
                            <select defaultValue={JSON.stringify(null)} onChange={handleChange} name="selectedSignature" style={{ width: "100%" }}>
                                <option style={{ textAlign: 'center' }} disabled value={JSON.stringify(null)}> -- Silahkan Pilih -- </option>
                                {props.data.signatures.map((el, index) => 
                                    <option key={index} value={JSON.stringify(el)}>{el.name}</option>
                                )}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td> <label> Pajak </label> </td>
                        <td> <input type="number" defaultValue={props.data.tax} style={{ width: 40, textAlign: "right" }} onChange={handleChange} name="tax" autoComplete="off" /> <span>%</span> </td>
                    </tr>
                    <tr>
                        <td colSpan={2}> <FormControlLabel control={<Checkbox name="withTax" onClick={handleChange} checked={props.data.withTax} />} label="Dengan Pajak" /> </td>
                    </tr>
                </tbody>
            </table>

            <table style={{ width: "60%", marginTop: 12 }}>
                <thead>
                    <tr>
                        <th>Produk</th>
                        <th>Berat Bersih (N.W.)</th>
                        <th>Harga Satuan</th>
                        <th> </th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.products.map((el, index) => (
                        <InvoiceProducts key={index} data={props.data} setData={props.setData} el={el} index={index}/>
                    ))}
                    <tr>
                        <td colSpan={4}> <button style={{ width: "100%" }} onClick={handleAdd}>+</button> </td>
                    </tr>
                </tbody>
            </table>

            <br/>
            
            <button style={{ width: 100, height: 36 }} onClick={handleSubmit}> Upload </button>
            <button style={{ width: 100, height: 36 }} onClick={() => {console.log(props.data)}}> TEST </button>
        </div>
    )
}

export default InvoiceForm
