import React, { createContext, useContext, useState } from 'react'

const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
  const isMobile = () => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    const mobileKeywords = ['iphone', 'android', 'windows phone', 'blackberry', 'mobile']

    return mobileKeywords.some(keyword => userAgent.includes(keyword))
  }

  const [global, setGlobal] = useState({
    isMobile: isMobile()
  })

  return (
    <GlobalContext.Provider value={[ global, setGlobal ]}>
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobal = () => {
  const context = useContext(GlobalContext)
  if (!context) {
    throw new Error('useGlobal must be used within a GlobalProvider')
  }
  return context
}
