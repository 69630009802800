import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import HomeIcon from '@mui/icons-material/Home'
import InventoryIcon from '@mui/icons-material/Inventory'
import DescriptionIcon from '@mui/icons-material/Description'

const Navbar = () => {
    const [open, setOpen] = useState({menu1: true, menu2: true})

    const handleClick = (menu) => {
        setOpen({
            ...open,
            [menu]: !open[menu]
        })
    }

    return (
        <div id='navbar'>
            <List sx={{ width: '100%' }}
                subheader={
                    <ListSubheader sx={{ fontSize: "16px", backgroundColor: 'rgba(0,0,0,0)', color: 'black', textAlign: 'center'  }}> PT GOMAS JAYA NUSANTARA </ListSubheader>
                }>
                <ListItemButton component={Link} to='/'>
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItemButton>

                <ListItemButton onClick={() => {handleClick('menu1')}}>
                    <ListItemIcon>
                        <InventoryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Daftar" />
                    {open.menu1 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open.menu1} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to='/invoice'>
                            <ListItemText primary="Invoice" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to='/mailing'>
                            <ListItemText primary="Mailing" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to='/waybill'>
                            <ListItemText primary="Waybill (Surat Jalan)" />
                        </ListItemButton>
                    </List>
                </Collapse>

                <ListItemButton onClick={() => {handleClick('menu2')}}>
                    <ListItemIcon>
                        <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Pembuatan Surat" />
                    {open.menu2 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open.menu2} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to='/add/invoice'>
                            <ListItemText primary="Invoice" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to='/add/mailing'>
                            <ListItemText primary="Mailing" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to='/add/waybill'>
                            <ListItemText primary="Waybill (Surat Jalan)" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>
        </div>
    )
}

export default Navbar
