import React from 'react'
import { Document } from "@react-pdf/renderer"
import MailingPage from './MailingPage'
import { arabToRoman } from 'roman-numbers'

const MailingDoc = (props) => {
    return (
        <Document title={`${props.data.selectedMailType ? props.data.selectedMailType.code 
            : "xx"}_${props.data.selectedShipperCompany ? props.data.selectedShipperCompany.code 
            : "xxx"}_${props.data.date.getFullYear().toString()}_${arabToRoman(props.data.date.getMonth() + 1)}_${props.data.no.toString().padStart(4, '0')}`} 
        creator="GOMAS">
            <MailingPage data={props.data} />

            {props.data.archives.map((el, index) => 
                <MailingPage data={props.data} file={el} key={`mailing-page-archive-${index}`} />
            )}
        </Document>
    )
}

export default MailingDoc
