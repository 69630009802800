import React from 'react'

const WaybillList = (props) => {
    const handleDelete = () => {
        const updatedProducts = props.data.selectedWaybill
        updatedProducts.splice(props.index, 1)
        
        props.setData({
            ...props.data,
            selectedWaybill: updatedProducts
        })
    }

    return (
        <tr>
            <td> {props.el.no} </td>
            <td> {props.el.driver} - {props.el.plate_number} </td>
            <td> <button onClick={handleDelete}>x</button> </td>
        </tr>
    )
}

export default WaybillList
