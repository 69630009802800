import React from 'react'
import { StyleSheet, PDFViewer } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    viewer: {
        width: "96%",
        height: "96vh"
    }
});

const Viewer = (props) => {
    return (
        <div id='viewer'>
            <PDFViewer style={styles.viewer} showToolbar={false}>
                {props.doc}
            </PDFViewer>
        </div>
    )
}

export default Viewer
