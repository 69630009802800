import React from 'react'
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { arabToRoman } from 'roman-numbers'

const styles = StyleSheet.create({
    page: {
        padding: 37.44,
        height: "100%",
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderBottom: "1.2px solid rgb(220, 220, 220)"
    },
    title: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
        marginBottom: 12,
        width: "100%"
    },
    logo: {
        objectFit: "cover",
        aspectRatio: "1 / 1",
        marginLeft: 6,
        width: 64,
        height: "auto"
    },
    headerText: {
        width: "100%",
        textAlign: "right",
        display: "flex",
        flexDirection: "column",
        fontSize: 11,
        marginTop: -10
    },
    info: {
        display: "flex",
        flexDirection: "row",
        fontSize: 10,
        marginTop: 12,
        gap: 26,
        justifyContent: "center"
    },
    company: {
        marginTop: 12,
        fontSize: 9
    },
    product: {
        marginTop: 18,
        fontSize: 11
    },
    sign: {
        marginTop: 80,
        fontSize: 11
    },
    footer: {
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        fontSize: 11,
        position: "absolute",
        bottom: 30,
        paddingLeft: 45.44,
        paddingRight: 45.44
    }
});

const WaybillPage = (props) => {
    return(
        <Page size="A4" style={{ fontFamily: "Arial" }}>
            <View style={styles.page}>
                <View style={styles.header}>
                    <View style={styles.title}>
                        <Image src={require("../../images/Logo.png")} style={styles.logo} />
                        <Text style={{ fontWeight: 700, fontSize: 24 }}>Waybill / Surat Jalan</Text>
                    </View>

                    <View style={styles.headerText}>
                        <Text style={{ fontWeight: 700, fontSize: 14, paddingBottom: 4 }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.name : "-"}</Text>
                        <Text>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.long_address.split(', ').slice(0, 3).join(', ') : "-"}</Text>
                        <Text>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.long_address.split(', ').slice(3).join(', ') : "-"}</Text>
                        <Text style={{ color: "blue", textDecoration: "underline" }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.email : "-"}</Text>
                        <Text>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.phone : "-"}</Text>
                    </View>
                </View>

                <View style={styles.info}>
                    <View style={{ alignItems: "center", gap: 2, width: 140 }}>
                        <Text style={{ color: "rgb(140, 140, 140)" }}>WAYBILL NO</Text>
                        <Text style={{ fontWeight: 500 }}>{`${props.data.type ? 'WI' : 'WO'}/${props.data.selectedShipperCompany ? props.data.selectedShipperCompany.code 
                            : "xxx"}/${props.data.date.getFullYear().toString()}/${arabToRoman(props.data.date.getMonth() + 1)}/${props.data.no.toString().padStart(4, '0')}`}</Text>
                    </View>
                    
                    <View style={{ alignItems: "center", gap: 2, width: 140 }}>
                        <Text style={{ color: "rgb(140, 140, 140)" }}>WAYBILL DATE</Text>
                        <Text style={{ fontWeight: 500 }}>{props.data.date.toLocaleDateString("id-ID", {year: "numeric", month: "long", day:"numeric"})}</Text>
                    </View>

                    {props.data.type ? ''
                    :
                        <>
                            <View style={{ alignItems: "center", gap: 2, width: 140 }}>
                                <Text style={{ color: "rgb(140, 140, 140)" }}>PO NO</Text>
                                <Text style={{ fontWeight: 500 }}>{props.data.po ? props.data.po : "-"}</Text>
                            </View>
                            <View style={{ alignItems: "center", gap: 2, width: 140 }}>
                                <Text style={{ color: "rgb(140, 140, 140)" }}>PLATE NO</Text>
                                <Text style={{ fontWeight: 500 }}>{props.data.plateNumber ? props.data.plateNumber : "-"}</Text>
                            </View>
                        </>
                    }
                </View>

                <View style={styles.company}>
                    <table style={{ width: "100%", gap: 8 }}>
                        <tr style={{ display: "flex", flexDirection: "row", gap: 40 }}>
                            <th style={{ width: "100%", height: 22, backgroundColor: "#fff8e9" }}> <Text style={{ fontSize: 10, fontWeight: 500, padding: "0 8", marginTop: 7 }}>SHIPPER</Text> </th>
                            <th style={{ width: "100%", height: 22, backgroundColor: "#fff8e9" }}> <Text style={{ fontSize: 10, fontWeight: 500, padding: "0 8", marginTop: 7 }}>CONSIGNEE</Text> </th>
                        </tr>
                        <tr style={{ display: "flex", flexDirection: "row", gap: 40 }}>
                            {props.data.type ? 
                                <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                    <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>FROM</Text> </td>
                                    <td style={{ width: "60%" }}> <Text style={{ paddingRight: 8 }}>{props.data.location ? props.data.location : "-"}</Text> </td>
                                </View>
                            :
                                <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                    <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>COMPANY NAME</Text> </td>
                                    <td style={{ width: "60%" }}> <Text style={{ paddingRight: 8 }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.name : "-"}</Text> </td>
                                </View>
                            }
                            
                            <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>COMPANY NAME</Text> </td>
                                <td style={{ width: "60%" }}> <Text style={{ paddingRight: 8 }}>{props.data.selectedConsigneeCompany ? props.data.selectedConsigneeCompany.name : "-"}</Text> </td>
                            </View>
                        </tr>
                        <tr style={{ display: "flex", flexDirection: "row", gap: 40 }}>
                            {props.data.type ? 
                                <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                    <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>DRIVER NAME</Text> </td>
                                    <td style={{ width: "60%" }}> <Text style={{ paddingRight: 8 }}>{props.data.driverName ? props.data.driverName : "-"}</Text> </td>
                                </View>
                            :
                                <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                    <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>ADDRESS</Text> </td>
                                    <td style={{ width: "60%" }}> <Text style={{ paddingRight: 8 }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.long_address ? props.data.selectedShipperCompany.long_address : "-" : "-"}</Text> </td>
                                </View>
                            }
                            
                            <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>ADDRESS</Text> </td>
                                <td style={{ width: "60%" }}> <Text style={{ paddingRight: 8 }}>{props.data.selectedConsigneeCompany ? props.data.selectedConsigneeCompany.long_address ? props.data.selectedConsigneeCompany.long_address : "-" : "-"}</Text> </td>
                            </View>
                        </tr>
                        <tr style={{ display: "flex", flexDirection: "row", gap: 40 }}>
                            {props.data.type ? 
                                <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                    <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>PLATE NUMBER</Text> </td>
                                    <td style={{ width: "60%" }}> <Text style={{ paddingRight: 8 }}>{props.data.plateNumber ? props.data.plateNumber : "-"}</Text> </td>
                                </View>
                            :
                                <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                    <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>PHONE / FAX</Text> </td>
                                    <td style={{ width: "60%" }}> <Text style={{ paddingRight: 8 }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.phone ? props.data.selectedShipperCompany.phone : "-" : "-"}</Text> </td>
                                </View>
                            }
                            
                            <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>PHONE / FAX</Text> </td>
                                <td style={{ width: "60%" }}> <Text style={{ paddingRight: 8 }}>{props.data.selectedConsigneeCompany ? props.data.selectedConsigneeCompany.phone ? props.data.selectedConsigneeCompany.phone : "-" : "-"}</Text> </td>
                            </View>
                        </tr>
                    </table>
                </View>
                
                <View style={styles.product}>
                    {props.data.type ?
                        <table style={{ width: "100%", gap: 4 }}>
                            <tr style={{ fontSize: 12, display: "flex", flexDirection: "row", textAlign: "center" }}>
                                <th style={{ width: "28%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text style={{ padding: "0 8", textAlign: "left" }}>Product</Text> </th>
                                <th style={{ width: "36%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Berat Asal</Text> </th>
                                <th style={{ width: "36%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Berat Tujuan</Text> </th>
                            </tr>
                            <tr style={{ fontSize: 12, display: "flex", flexDirection: "row", textAlign: "center", marginTop: "-10px" }}>
                                <th style={{ width: "28%", height: 36, backgroundColor: "#fff8e9"}}></th>
                                <th style={{ width: "12%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Bruto</Text> </th>
                                <th style={{ width: "12%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Tara</Text> </th>
                                <th style={{ width: "12%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Netto</Text> </th>

                                <th style={{ width: "12%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Bruto</Text> </th>
                                <th style={{ width: "12%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Tara</Text> </th>
                                <th style={{ width: "12%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Netto</Text> </th>
                            </tr>
                            <View style={{ borderBottom: "0.7px solid black" }}>
                                {props.products.map((el, index) => (
                                    <tr key={index} style={{ display: "flex", flexDirection: "row", textAlign: "center" }}>
                                        <td style={{ width: "28%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text style={{ padding: "0 8", textAlign: "left", fontWeight: 700 }}>{el.name}</Text> </td>
                                        <td style={{ width: "12%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text>_________</Text> </td>
                                        <td style={{ width: "12%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text>_________</Text> </td>
                                        <td style={{ width: "12%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text>_________</Text> </td>
                                        <td style={{ width: "12%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text>_________</Text> </td>
                                        <td style={{ width: "12%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text>_________</Text> </td>
                                        <td style={{ width: "12%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text>_________</Text> </td>
                                    </tr>
                                ))}
                            </View>
                        </table>
                    :
                        <table style={{ width: "100%", gap: 4 }}>
                            <tr style={{ fontSize: 12, display: "flex", flexDirection: "row", textAlign: "center" }}>
                                <th style={{ width: "28%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text style={{ padding: "0 8", textAlign: "left" }}>Product</Text> </th>
                                <th style={{ width: "36%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Berat Asal</Text> </th>
                                <th style={{ width: "36%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Berat Tujuan</Text> </th>
                            </tr>
                            <tr style={{ fontSize: 12, display: "flex", flexDirection: "row", textAlign: "center", marginTop: "-10px" }}>
                                <th style={{ width: "28%", height: 36, backgroundColor: "#fff8e9"}}></th>
                                <th style={{ width: "12%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Bruto</Text> </th>
                                <th style={{ width: "12%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Tara</Text> </th>
                                <th style={{ width: "12%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Netto</Text> </th>

                                <th style={{ width: "12%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Bruto</Text> </th>
                                <th style={{ width: "12%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Tara</Text> </th>
                                <th style={{ width: "12%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Netto</Text> </th>
                            </tr>
                            <View style={{ borderBottom: "0.7px solid black" }}>
                                {props.products.map((el, index) => (
                                    <tr key={index} style={{ display: "flex", flexDirection: "row", textAlign: "center" }}>
                                        <td style={{ width: "28%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text style={{ padding: "0 8", textAlign: "left", fontWeight: 700 }}>{el.name}</Text> </td>
                                        <td style={{ width: "12%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text>{el.grossWeight ? el.grossWeight : "_________"}</Text> </td>
                                        <td style={{ width: "12%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text>{el.tareWeight ? el.tareWeight : "_________"}</Text> </td>
                                        <td style={{ width: "12%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text>{el.netWeight ? el.netWeight : "_________"}</Text> </td>
                                        <td style={{ width: "12%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text>_________</Text> </td>
                                        <td style={{ width: "12%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text>_________</Text> </td>
                                        <td style={{ width: "12%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text>_________</Text> </td>
                                    </tr>
                                ))}
                            </View>
                        </table>
                    }
                    
                </View>

                <View style={styles.sign}>
                    <table style={{ width: "100%" }}>
                        {props.data.type ? 
                            <tr style={{ display: "flex", flexDirection: "row" }}>
                                <td style={{ width: "50%", justifyContent: "center", textAlign: "center", marginTop: 4, gap: 4 }}>
                                    <Text>Sopir</Text>
                                    <Text> </Text>
                                    <Text> </Text>
                                    <Text> </Text>
                                    <Text> </Text>
                                    <Text>{props.data.driverName ? props.data.driverName : "____________________"}</Text>
                                    <Text> </Text>
                                </td>
                                <td style={{ width: "50%", justifyContent: "center", textAlign: "center", marginTop: 4, gap: 4 }}>
                                    <Text>Diterima</Text>
                                    <Text> </Text>
                                    <Text> </Text>
                                    <Text> </Text>
                                    <Text> </Text>
                                    <Text>____________________</Text>
                                    <Text>{props.data.selectedSignature ? props.data.selectedSignature.position : " "}</Text>
                                </td>
                            </tr>
                        :
                            <tr style={{ display: "flex", flexDirection: "row" }}>
                                <td style={{ width: "50%", justifyContent: "center", textAlign: "center", marginTop: 4, gap: 4 }}>
                                    <Text>Disetujui</Text>
                                    <Text> </Text>
                                    <Text> </Text>
                                    <Text> </Text>
                                    <Text> </Text>
                                    <Text style={props.data.selectedSignature ? { fontWeight: 700, textDecoration: 'underline' } : {}}>{props.data.selectedSignature ? props.data.selectedSignature.name : "____________________"}</Text>
                                    <Text>{props.data.selectedSignature ? props.data.selectedSignature.position : " "}</Text>
                                </td>
                                <td style={{ width: "50%", justifyContent: "center", textAlign: "center", marginTop: 4, gap: 4 }}>
                                    <Text>Diterima</Text>
                                    <Text> </Text>
                                    <Text> </Text>
                                    <Text> </Text>
                                    <Text> </Text>
                                    <Text>____________________</Text>
                                    <Text>{props.data.selectedSignature ? props.data.selectedSignature.position : " "}</Text>
                                </td>
                            </tr>
                        }
                        
                    </table>
                </View>

                <View style={styles.footer}>
                    <View style={{ fontSize: 8, width: "50%" }}>
                        <Text> </Text>
                        <Text> </Text>
                        <Text> </Text>
                    </View>
                    <View style={{ width: "50%", textAlign: "right", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <Text>Page</Text>
                        <Text style={{ fontWeight: 700 }}>{` ${props.pageNumber}`}</Text>
                        <Text> of</Text>
                        <Text style={{ fontWeight: 700 }}>{` ${props.totalPages}`}</Text>
                    </View>
                </View>
            </View>
        </Page>
    )
}

export default WaybillPage
