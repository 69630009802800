import React, { useEffect, useState } from 'react'
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import angkaTerbilang from '@develoka/angka-terbilang-js';
import { arabToRoman } from 'roman-numbers'

const styles = StyleSheet.create({
    page: {
        padding: 37.44,
        height: "100%"
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderBottom: "1.2px solid rgb(220, 220, 220)"
    },
    title: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
        marginBottom: 12,
        width: "100%"
    },
    logo: {
        objectFit: "cover",
        aspectRatio: "1 / 1",
        marginLeft: 6,
        width: 64,
        height: "auto"
    },
    headerText: {
        width: "100%",
        textAlign: "right",
        display: "flex",
        flexDirection: "column",
        fontSize: 11,
        marginTop: -10
    },
    info: {
        display: "flex",
        flexDirection: "row",
        fontSize: 10,
        marginTop: 12,
        gap: 26,
        justifyContent: "center"
    },
    waybill_list: {
        fontSize: 10,
        marginTop: 6,
        display: "flex",
        flexDirection: "row",
        justifyItem: "center",
    },
    company: {
        marginTop: 12,
        fontSize: 9
    },
    product: {
        marginTop: 18,
        fontSize: 11
    },
    sign: {
        marginTop: 30,
        fontSize: 11
    },
    footer: {
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        fontSize: 11,
        position: "absolute",
        bottom: 30,
        paddingLeft: 45.44,
        paddingRight: 45.44
    }
});

const InvoicePage = (props) => {
    const [waybills, setWaybills] = useState('')

    useEffect(() => {
        const subTotal = props.data.products.reduce((total, current) => {
            return total + parseInt(current.amount.replace(/[^0-9]/g, ''))
        }, 0)

        const tax = Math.floor(subTotal * props.data.tax / 100)

        props.setData({
            ...props.data,
            subTotal: subTotal,
            taxAmount: props.data.withTax ? tax : 0,
            totalValue: props.data.withTax ? subTotal + tax : subTotal
        })
    }, [props.data.products, props.data.tax, props.data.withTax])

    useEffect(() => {
        const array = props.data.selectedWaybill.sort((a, b) => a.id - b.id).map(el => el.no)
        
        setWaybills(formatArray(array))
    }, [props.data])

    return(
        <Page size="A4" style={{ fontFamily: "Arial" }}>
            <View style={styles.page}>
                <View style={styles.header}>
                    <View style={styles.title}>
                        <Image src={require("../../images/Logo.png")} style={styles.logo} />
                        <Text style={{ fontWeight: 700, fontSize: 24 }}>Commercial Invoice</Text>
                    </View>

                    <View style={styles.headerText}>
                        <Text style={{ fontWeight: 700, fontSize: 14, paddingBottom: 4 }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.name : "-"}</Text>
                        <Text>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.long_address.split(', ').slice(0, 3).join(', ') : "-"}</Text>
                        <Text>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.long_address.split(', ').slice(3).join(', ') : "-"}</Text>
                        <Text style={{ color: "blue", textDecoration: "underline" }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.email : "-"}</Text>
                        <Text>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.phone : "-"}</Text>
                    </View>
                </View>

                <View style={styles.info}>
                    <View style={{ alignItems: "center", gap: 2, width: 140 }}>
                        <Text style={{ color: "rgb(140, 140, 140)" }}>INVOICE NO</Text>
                        <Text style={{ fontWeight: 500 }}>{`CI/${props.data.selectedShipperCompany ? props.data.selectedShipperCompany.code 
                            : "xxx"}/${props.data.date.getFullYear().toString()}/${arabToRoman(props.data.date.getMonth() + 1)}/${props.data.no.toString().padStart(4, '0')}`}</Text>
                    </View>
                    
                    <View style={{ alignItems: "center", gap: 2, width: 140 }}>
                        <Text style={{ color: "rgb(140, 140, 140)" }}>INVOICE DATE</Text>
                        <Text style={{ fontWeight: 500 }}>{props.data.date.toLocaleDateString("id-ID", {year: "numeric", month: "long", day:"numeric"})}</Text>
                    </View>

                    <View style={{ alignItems: "center", gap: 2, width: 140 }}>
                        <Text style={{ color: "rgb(140, 140, 140)" }}>PO NO</Text>
                        <Text style={{ fontWeight: 500 }}>{props.data.po ? props.data.po : "-"}</Text>
                    </View>
                </View>

                <View style={styles.waybill_list}>
                        <Text style={{ color: "rgb(140, 140, 140)" }}>WAYBILL NO :  </Text>
                        <Text style={{ fontWeight: 500, marginTop: "2px" }}>{waybills}</Text>
                </View>

                <View style={styles.company}>
                    <table style={{ width: "100%", gap: 8 }}>
                        <tr style={{ display: "flex", flexDirection: "row", gap: 40 }}>
                            <th style={{ width: "100%", height: 22, backgroundColor: "#fff8e9" }}> <Text style={{ fontSize: 10, fontWeight: 500, padding: "0 8", marginTop: 7 }}>SHIPPER</Text> </th>
                            <th style={{ width: "100%", height: 22, backgroundColor: "#fff8e9" }}> <Text style={{ fontSize: 10, fontWeight: 500, padding: "0 8", marginTop: 7 }}>CONSIGNEE</Text> </th>
                        </tr>
                        <tr style={{ display: "flex", flexDirection: "row", gap: 40 }}>
                            <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>COMPANY NAME</Text> </td>
                                <td style={{ width: "60%" }}> <Text style={{ paddingRight: 8 }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.name : "-"}</Text> </td>
                            </View>
                            <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>COMPANY NAME</Text> </td>
                                <td style={{ width: "60%" }}> <Text style={{ paddingRight: 8 }}>{props.data.selectedConsigneeCompany ? props.data.selectedConsigneeCompany.name : "-"}</Text> </td>
                            </View>
                        </tr>
                        <tr style={{ display: "flex", flexDirection: "row", gap: 40 }}>
                            <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>ADDRESS</Text> </td>
                                <td style={{ width: "60%" }}> <Text style={{ paddingRight: 8 }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.long_address : "-"}</Text> </td>
                            </View>
                            <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>ADDRESS</Text> </td>
                                <td style={{ width: "60%" }}> <Text style={{ paddingRight: 8 }}>{props.data.selectedConsigneeCompany ? props.data.selectedConsigneeCompany.long_address ? props.data.selectedConsigneeCompany.long_address : "-" : "-"}</Text> </td>
                            </View>
                        </tr>
                        <tr style={{ display: "flex", flexDirection: "row", gap: 40 }}>
                            <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                <td style={{ width: "40%" }}></td>
                                <td style={{ width: "60%" }}></td>
                            </View>
                            <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>PHONE / FAX</Text> </td>
                                <td style={{ width: "60%" }}> <Text style={{ paddingRight: 8 }}>{props.data.selectedConsigneeCompany ? `${props.data.selectedConsigneeCompany.phone ? props.data.selectedConsigneeCompany.phone : "-"} / ${props.data.selectedConsigneeCompany.fax ? props.data.selectedConsigneeCompany.fax : "-"}`  : "-"}</Text> </td>
                            </View>
                        </tr>
                        <tr style={{ display: "flex", flexDirection: "row", gap: 40 }}>
                            <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                <td style={{ width: "40%" }}></td>
                                <td style={{ width: "60%" }}></td>
                            </View>
                            <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>EMAIL</Text> </td>
                                <td style={{ width: "60%" }}> <Text style={{ paddingRight: 8 }}>{props.data.selectedConsigneeCompany ? props.data.selectedConsigneeCompany.email ? props.data.selectedConsigneeCompany.email : "-" : "-"}</Text> </td>
                            </View>
                        </tr>
                        <tr style={{ display: "flex", flexDirection: "row", gap: 40 }}>
                            <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>NPWP</Text> </td>
                                <td style={{ width: "60%" }}> <Text>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.npwp : "-"}</Text> </td>
                            </View>
                            <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                <td style={{ width: "40%" }}> <Text style={{ color: "rgb(140, 140, 140)", padding: "0 8" }}>NPWP</Text> </td>
                                <td style={{ width: "60%" }}> <Text style={{ paddingRight: 8 }}>{props.data.selectedConsigneeCompany ? props.data.selectedConsigneeCompany.npwp ? props.data.selectedConsigneeCompany.npwp : "-" : "-"}</Text> </td>
                            </View>
                        </tr>
                    </table>
                </View>
                
                <View style={styles.product}>
                    <table style={{ width: "100%", gap: 4 }}>
                        <tr style={{ fontSize: 12, display: "flex", flexDirection: "row", textAlign: "center" }}>
                            <th style={{ width: "35%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text style={{ padding: "0 8", textAlign: "left" }}>Product</Text> </th>
                            <th style={{ width: "26%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text>Net Weight</Text> </th>
                            <th style={{ width: "17%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text style={{ padding: "0 8", textAlign: "right" }}>Unit Price</Text> </th>
                            <th style={{ width: "22%", height: 36, backgroundColor: "#fff8e9", justifyContent: "center" }}> <Text style={{ padding: "0 8", textAlign: "right" }}>Amount</Text> </th>
                        </tr>
                        <View style={{ borderBottom: "0.7px solid black" }}>
                            {props.products.map((el, index) => (
                                <tr key={index} style={{ display: "flex", flexDirection: "row", textAlign: "center" }}>
                                    <td style={{ width: "35%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text style={{ padding: "0 8", textAlign: "left", fontWeight: 700 }}>{el.name}</Text> </td>
                                    <td style={{ width: "26%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text>{el.netWeight}</Text> </td>
                                    <td style={{ width: "17%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text style={{ padding: "0 8", textAlign: "right" }}>{el.unitPrice}</Text> </td>
                                    <td style={{ width: "22%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text style={{ padding: "0 8", textAlign: "right", fontWeight: 700 }}>{el.amount}</Text> </td>
                                </tr>
                            ))}
                        </View>
                        {props.pageNumber === props.totalPages ? 
                            <>
                                {props.data.withTax ? <tr style={{ display: "flex", flexDirection: "row", textAlign: "center", borderBottom: "1.2px solid rgb(220, 220, 220)" }}>
                                    <td style={{ width: "35%", height: 26, justifyContent: "center", marginBottom: 4 }}></td>
                                    <td style={{ width: "26%", height: 26, justifyContent: "center", marginBottom: 4 }}></td>
                                    <td style={{ width: "17%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text style={{ padding: "0 8", textAlign: "right", color: "rgb(140, 140, 140)" }}>Sub Total</Text> </td>
                                    <td style={{ width: "22%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text style={{ padding: "0 8", textAlign: "right", color: "rgb(140, 140, 140)" }}>{props.data.subTotal ? props.data.subTotal.toLocaleString("id-ID", { style: "currency", currency: "IDR" }).replace(',00', '').replace(/\s+/g, "") : ''}</Text> </td>
                                </tr> : ''}
                                {props.data.withTax ? <tr style={{ display: "flex", flexDirection: "row", textAlign: "center", borderBottom: "1.4px solid black" }}>
                                    <td style={{ width: "35%", height: 26, justifyContent: "center", marginBottom: 4 }}></td>
                                    <td style={{ width: "26%", height: 26, justifyContent: "center", marginBottom: 4 }}></td>
                                    <td style={{ width: "17%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text style={{ padding: "0 8", textAlign: "right", color: "rgb(140, 140, 140)" }}>{`Tax(${props.data.tax}%)`}</Text> </td>
                                    <td style={{ width: "22%", height: 26, justifyContent: "center", marginBottom: 4 }}> <Text style={{ padding: "0 8", textAlign: "right", color: "rgb(140, 140, 140)" }}>{props.data.taxAmount ? props.data.taxAmount.toLocaleString("id-ID", { style: "currency", currency: "IDR" }).replace(',00', '').replace(/\s+/g, "") : ''}</Text> </td>
                                </tr> : ''}
                                <tr style={{ display: "flex", flexDirection: "row", textAlign: "center" }}>
                                    <td style={{ width: "61%", height: 26, justifyContent: "center", marginTop: 4, gap: 8 }}>
                                        <Text style={{ padding: "0 8", textAlign: "left" }}>In words:</Text>
                                        <Text style={{ padding: "0 8", textAlign: "left" }}>{`${angkaTerbilang(props.data.totalValue).toUpperCase()} RUPIAH`}</Text>
                                    </td>
                                    <td style={{ width: "17%", height: 26, justifyContent: "center", marginTop: 4 }}> <Text style={{ padding: "0 8", textAlign: "right" }}>Total Value</Text> </td>
                                    <td style={{ width: "22%", height: 26, justifyContent: "center", marginTop: 4 }}> <Text style={{ padding: "0 8", textAlign: "right", fontSize: 14, fontWeight: 700 }}>{props.data.totalValue ? props.data.totalValue.toLocaleString("id-ID", { style: "currency", currency: "IDR" }).replace(',00', '').replace(/\s+/g, "") : ''}</Text> </td>
                                </tr>
                            </>
                        : <></>}
                    </table>
                </View>

                <View style={styles.sign}>
                    <table style={{ width: "100%" }}>
                        <tr style={{ display: "flex", flexDirection: "row", gap: 120 }}>
                            <td style={{ width: "60%", justifyContent: "center" }}>
                                <Text style={{ padding: "0 8", textAlign: "left" }}>Pembayaran atas invoice ini dinyatakan sah, apabila dana telah masuk ke rekening kami pada:</Text>
                                <Text> </Text>
                                <Text style={{ padding: "0 8", textAlign: "left" }}>Bank Central Asia</Text>
                                <Text style={{ padding: "0 8", textAlign: "left" }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.bank_no : ''}</Text>
                                <Text style={{ padding: "0 8", textAlign: "left" }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.name : ''}</Text>
                                <Text style={{ padding: "0 8", textAlign: "left" }}>KCU MAKASSAR</Text>
                            </td>
                            {props.pageNumber === props.totalPages ? 
                                <td style={{ width: "40%", justifyContent: "center", textAlign: "center", marginTop: 4, gap: 4 }}>
                                    <Text>Disetujui</Text>
                                    <Text> </Text>
                                    <Text> </Text>
                                    <Text> </Text>
                                    <Text> </Text>
                                    <Text style={props.data.selectedSignature ? { fontWeight: 700, textDecoration: 'underline' } : {}}>{props.data.selectedSignature ? props.data.selectedSignature.name : "____________________"}</Text>
                                    <Text>{props.data.selectedSignature ? props.data.selectedSignature.position : ""}</Text>
                                </td>
                            : ''}
                            
                        </tr>
                    </table>
                </View>

                <View style={styles.footer}>
                    <View style={{ fontSize: 8, width: "50%" }}>
                        <Text>Lembar ke-1 : Pembeli</Text>
                        <Text>Lembar ke-2 : Akunting</Text>
                        <Text>Lembar ke-3 : Penjualan</Text>
                    </View>
                    <View style={{ width: "50%", textAlign: "right", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <Text>Page</Text>
                        <Text style={{ fontWeight: 700 }}>{` ${props.pageNumber}`}</Text>
                        <Text> of</Text>
                        <Text style={{ fontWeight: 700 }}>{` ${props.totalPages}`}</Text>
                    </View>
                </View>
            </View>
        </Page>
    )
}

export default InvoicePage

// APABILA [WB......] [WB.....] terlalu panjang maka [WB....] yang tidak cukup turun ke bawah tanpa memotong!
function formatArray(a) {
    // Helper function to parse the parts of the identifier
    function parseIdentifier(id) {
        const parts = id.split('_');
        return {
            prefix: parts.slice(0, 4).join('_'),
            number: parseInt(parts[4], 10)
        };
    }

    // Step 1: Parse the array into a map grouped by prefix
    const groups = {};
    for (const id of a) {
        const { prefix, number } = parseIdentifier(id);
        if (!groups[prefix]) {
            groups[prefix] = [];
        }
        groups[prefix].push(number);
    }

    // Step 2: Sort the numbers in each group and create ranges
    function createRanges(numbers) {
        numbers.sort((a, b) => a - b);
        const ranges = [];
        let start = numbers[0];
        let end = numbers[0];
        
        for (let i = 1; i < numbers.length; i++) {
            if (numbers[i] === end + 1) {
                end = numbers[i];
            } else {
                ranges.push({ start, end });
                start = numbers[i];
                end = numbers[i];
            }
        }
        ranges.push({ start, end });
        return ranges;
    }

    // Step 3: Format the groups and ranges into the desired string
    const formattedParts = [];
    for (const prefix in groups) {
        const ranges = createRanges(groups[prefix]);
        const prefixFormatted = prefix.replace(/_/g, '/');
        const rangeStrings = ranges.map(range => {
            if (range.start === range.end) {
                return `${String(range.start).padStart(4, '0')}`;
            } else {
                return `${String(range.start).padStart(4, '0')} - ${String(range.end).padStart(4, '0')}`;
            }
        });
        formattedParts.push(`[${prefixFormatted}/${rangeStrings.join(', ')}]`);
    }

    return formattedParts.join(' ');
}
