import React, { useEffect, useState } from 'react'
import { Document } from "@react-pdf/renderer";
import WaybillPage from './WaybillPage';
import { arabToRoman } from 'roman-numbers'
import NewsPage from './NewsPage';

const WaybillDoc = (props) => {
    const [pages, setPages] = useState(<></>)

    useEffect(() => {
        setPages(<></>)
        let totalPages = Math.floor((props.data.products.length-1)/5)+1

        for(let i=0; i<props.data.products.length; i+=5){
            const batch = props.data.products.slice(i, i + 5);

            setPages(p => (
                <>
                    {p}
                    <WaybillPage data={props.data} products={batch} pageNumber={Math.ceil((i+1)/5)} totalPages={totalPages} />
                </>
            ))

        }

        if(props.data.news){
            setPages(p => (
                <>
                    {p}
                    <NewsPage data={props.data} />
                </>
            ))
        }
    }, [props.data])

    return (
        <Document title={`${props.data.type ? 'WI' : 'WO'}_${props.data.selectedShipperCompany ? props.data.selectedShipperCompany.code
            : "xxx"}_${props.data.date.getFullYear().toString()}_${arabToRoman(props.data.date.getMonth() + 1)}_${props.data.no.toString().padStart(4, '0')}`} 
        creator="GOMAS">
            {pages}
        </Document>
    )
}

export default WaybillDoc
