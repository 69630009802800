import React, { useEffect, useState } from 'react'
import { Document } from "@react-pdf/renderer";
import InvoicePage from './InvoicePage';
import { arabToRoman } from 'roman-numbers'

const InvoiceDoc = (props) => {
    const [pages, setPages] = useState(<></>)

    useEffect(() => {
        setPages(<></>)
        let totalPages = Math.floor((props.data.products.length-1)/3)+1

        for(let i=0; i<props.data.products.length; i+=3){
            const batch = props.data.products.slice(i, i + 3);

            setPages(p => (
                <>
                    {p}
                    <InvoicePage data={props.data} setData={props.setData} products={batch} pageNumber={Math.ceil((i+1)/3)} totalPages={totalPages} />
                </>
            ))

        }
    }, [props.data])

    return (
        <Document title={`CI_${props.data.selectedShipperCompany ? props.data.selectedShipperCompany.code 
            : "xxx"}_${props.data.date.getFullYear().toString()}_${arabToRoman(props.data.date.getMonth() + 1)}_${props.data.no.toString().padStart(4, '0')}`} 
        creator="GOMAS">
            {pages}
        </Document>
    )
}

export default InvoiceDoc
