import { Button, TextField } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import { useGlobal } from '../GlobalProvider'
import { config } from '../config'

const LoginPage = () => {
    const [global, setGlobal] = useGlobal()
    const [loginInput, setLoginInput] = useState({
        username: '',
        password: ''
    })

    const handleChange = (e) => {
        setLoginInput({
            ...loginInput,
            [e.target.name]: e.target.value
        })
    }

    const login = (e) => {
        e.preventDefault()
        
        axios.post(`${config.API_HOST}/admin/login`, loginInput)
            .then(res => {
                setGlobal({
                    ...global,
                    user: res.data.user,
                    notif: {
                        warning: false,
                        message: res.data.message
                    }
                })
            })
            .catch(err => {
                if(err.response){
                    setGlobal({
                        ...global,
                        notif: {
                            warning: true,
                            message: err.response.data.message
                        }
                    })
                } else {
                    setGlobal({
                        ...global,
                        notif: {
                            warning: true,
                            message: `${err.message}!`
                        }
                    })
                }
            })
    }

    return (
        <form id='login-page' onSubmit={login}>
            <TextField required className='login-input' name='username' label='Username' variant='standard' value={loginInput.username} onChange={handleChange} />
            <TextField required className='login-input' name='password' label='Password' type='password' variant='standard' value={loginInput.password} onChange={handleChange} />
            <Button className='login-input' type='submit'> Login </Button>
        </form>
    )
}

export default LoginPage
