import React, { cloneElement, useState } from 'react'
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer"
import { arabToRoman } from 'roman-numbers'
import { parseQuillDelta } from 'quilljs-parser'
import { pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const styles = StyleSheet.create({
    page: {
        padding: 37.44,
        height: "100%",
        fontSize: 11
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderBottom: "1.2px solid rgb(220, 220, 220)"
    },
    title: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
        marginBottom: 12,
        width: "20%"
    },
    logo: {
        objectFit: "cover",
        aspectRatio: "1 / 1",
        marginLeft: 6,
        width: 64,
        height: "auto"
    },
    headerText: {
        width: "100%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        marginTop: -20
    },
    info: {
        marginTop: 15
    },
    consignee: {
        marginTop: 50,
        gap: 7
    },
    mainText: {
        marginTop: 8
    },
    sign: {
        marginTop: 50
    }
});

const MailingPage = (props) => {
    let content

    const [pdfData, setPdfData] = useState([])
    const [pageNum, setPageNum] = useState(1)
    const [numPages, setNumPages] = useState(null)

    const deltaToPDF = (delta, style) => {
        const parsedQuill = parseQuillDelta(delta)
        let final = <View style={style}></View>
        let orderedCount = 1

        const styleText = (text) => {
            if(text.attributes){
                return {
                    ...text.attributes.bold ? { fontWeight : 'bold' } : null,
                    ...text.attributes.underline && text.attributes.strike ? { textDecoration : 'underline line-through' } : text.attributes.underline ? { textDecoration : 'underline' } : text.attributes.strike ? { textDecoration : 'line-through' } : null,
                    ...text.attributes.italic ? { fontStyle : 'italic' } : null
                }
            }
            return {}
        }

        parsedQuill.paragraphs.map((el1, index1) => {
            let text = <Text key={`text1-${index1}`}></Text>
            let row = <View key={`row-${index1}`} style={{ display: 'flex', flexDirection: 'row' }}></View>
            let cell1 = <Text key={`cell1-${index1}`} style={{ display: 'flex', flexDirection: 'row', width: '25%' }}></Text>
            let cell2 = <Text key={`cell2-${index1}`} style={{ display: 'flex', flexDirection: 'row', flex: 1 }}></Text>
            let isColon = false
            el1.array = []

            if(el1.attributes){
                switch(el1.attributes.list){
                    case 'bullet':
                        row = cloneElement(row, null, [row.props.children, 
                            <Text key={`list-${index1}`} style={{ textAlign: 'center', width: '5%', marginLeft: 6 }}>•</Text>])
                        orderedCount = 1
                        break
                    case 'ordered':
                        row = cloneElement(row, null, [row.props.children, 
                            <Text key={`list-${index1}`} style={{ textAlign: 'center', width: '5%', marginLeft: 6 }}>{`${orderedCount}.`}</Text>])
                        orderedCount += 1
                        break
                }

                el1.textRuns.map((el2, index2) => {
                    if(el2.text.includes(':')){
                        el1.table = true

                        el2.text.replace(/\t/g, '').split(':').map((element, index) => {
                            if(index === 0){
                                if(element.trim()){
                                    el1.array.push({
                                        text: element,
                                        ...el2.attributes ? {attributes: el2.attributes} : {}
                                    })
                                }
                            } else {
                                el1.array.push({
                                    text: `:${element}`,
                                    ...el2.attributes ? {attributes: el2.attributes} : {}
                                })
                            }
                        })
                    } else {
                        el1.array.push({
                            text: el2.text.replace(/\t/g, ''),
                            ...el2.attributes ? {attributes: el2.attributes} : {}
                        })
                    }
                })

                el1.array.map((el2, index2) => {
                    if(el1.table){
                        if(!el2.text.includes(':') && !isColon){
                            cell1 = cloneElement(cell1, null, [cell1.props.children,
                                <Text style={styleText(el2)} key={`text1-${index1} text-2-${index2}`}>{el2.text}</Text>])
                            return
                        } else if(el2.text.includes(':')){
                            isColon = true
                        }

                        cell2 = cloneElement(cell2, null, [cell2.props.children,
                            <Text style={styleText(el2)} key={`text1-${index1} text-2-${index2}`}>{`${el2.text}`}</Text>])
                    } else {
                        row = cloneElement(row, null, [row.props.children, 
                            <Text style={styleText(el2)} key={`text1-${index1} text-2-${index2}`}>{el2.text}</Text>])
                    }
                })

                if(el1.table){
                    row = cloneElement(row, null, [row.props.children, cell1, cell2])
                }

                final = cloneElement(final, null, [final.props.children, row])
            } else {
                orderedCount = 1

                el1.textRuns.map((el2, index2) => {
                    if(!el2.text && el1.textRuns.length === 1){
                        text = cloneElement(text, null, [text.props.children, <Text key={`text1-${index1} text-2-${index2}`}>{'\n'}</Text>])
                        return
                    }
    
                    text = cloneElement(text, null, [text.props.children, 
                        <Text style={styleText(el2)} key={`text1-${index1} text-2-${index2}`}>{el2.text.replace(/\t/g, ' ')}</Text>])
                })

                final = cloneElement(final, null, [final.props.children, text])
            }
        })
        
        return final
    }

    const convertToImage = async (file) => {
        const fileReader = new FileReader()

        fileReader.onload = async (event) => {
            const arrayBuffer = event.target.result
            const pdf = await pdfjs.getDocument(arrayBuffer).promise
            const totalPages = pdf.numPages
            
            let imageDataArray = []
            for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
            const page = await pdf.getPage(pageNumber)
            const viewport = page.getViewport({ scale: 1 })
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')
            canvas.width = viewport.width
            canvas.height = viewport.height
            const renderContext = {
                canvasContext: context,
                viewport: viewport
            }
            await page.render(renderContext).promise
            const imageData = canvas.toDataURL('image/png')
            imageDataArray.push(imageData)
            }
            
            setPdfData(imageDataArray)
        }
        fileReader.readAsArrayBuffer(file)
    }

    if(props.file){
        if(props.file.type == 'application/pdf'){
            convertToImage(props.file)

            content = 
            <>
                {pdfData.map((el, index) => 
                    <Image src={el} key={index} />
                )}
            </>
        } else {
            content =
            <View style={styles.page}>
                <View style={styles.header}>
                    <View style={styles.title}>
                        <Image src={require("../../images/Logo.png")} style={styles.logo} />
                    </View>

                    <View style={styles.headerText}>
                        <Text style={{ fontWeight: 700, fontSize: 20, paddingBottom: 4 }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.name : "-"}</Text>
                        <Text>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.long_address : "-"}</Text>
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Text>Telepon: </Text>
                            <Text>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.phone : "-"}</Text>
                            <Text> </Text>
                            <Text>Email: </Text>
                            <Text style={{ color: "blue", textDecoration: "underline" }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.email : "-"}</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.info}>
                    <Image src={URL.createObjectURL(props.file)} />
                </View>
            </View>

            URL.revokeObjectURL(props.file)
        }
    } else {
        content = 
        <View style={styles.page}>
            <View style={styles.header}>
                <View style={styles.title}>
                    <Image src={require("../../images/Logo.png")} style={styles.logo} />
                </View>

                <View style={styles.headerText}>
                    <Text style={{ fontWeight: 700, fontSize: 20, paddingBottom: 4 }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.name : "-"}</Text>
                    <Text>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.long_address : "-"}</Text>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <Text>Telepon: </Text>
                        <Text>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.phone : "-"}</Text>
                        <Text> </Text>
                        <Text>Email: </Text>
                        <Text style={{ color: "blue", textDecoration: "underline" }}>{props.data.selectedShipperCompany ? props.data.selectedShipperCompany.email : "-"}</Text>
                    </View>
                </View>
            </View>

            <View style={styles.info}>
                <table style={{ width: "100%", gap: 5 }}>
                    <tr style={{ display: "flex", flexDirection: "row" }}>
                        <td style={{ width: "15%" }}><Text>Nomor</Text></td>
                        <td style={{ width: "2%" }}><Text>:</Text></td>
                        <td style={{ width: "43%" }}><Text>{`${props.data.selectedMailType ? props.data.selectedMailType.code 
                            : "xx"}/${props.data.selectedShipperCompany ? props.data.selectedShipperCompany.code 
                            : "xxx"}/${props.data.date.getFullYear().toString()}/${arabToRoman(props.data.date.getMonth() + 1)}/${props.data.no.toString().padStart(4, '0')}`}</Text></td>
                        <td style={{ width: "40%", textAlign: "right" }}>
                            <Text>{`Makassar, ${props.data.date.toLocaleDateString("id-ID", {year: "numeric", month: "long", day:"numeric"})}`}</Text>
                        </td>
                    </tr>
                    <tr style={{ display: "flex", flexDirection: "row" }}>
                        <td style={{ width: "15%" }}><Text>Lampiran</Text></td>
                        <td style={{ width: "2%" }}><Text>:</Text></td>
                        <td style={{ width: "35%" }}><Text>{props.data.archives ? props.data.archives.length != 0 ? props.data.archives.length : '-' : '-'}</Text></td>
                    </tr>
                    <tr style={{ display: "flex", flexDirection: "row" }}>
                        <td style={{ width: "15%" }}><Text>Perihal</Text></td>
                        <td style={{ width: "2%" }}><Text>:</Text></td>
                        <td style={{ width: "35%", textDecoration: props.data.subject ? 'underline' : '' }}><Text>{props.data.subject ? props.data.subject : '-'}</Text></td>
                    </tr>
                </table>
            </View>

            <View style={styles.consignee}>
                <View style={{ gap: 7 }}>
                    <Text>Kepada Yth.</Text>
                    <Text>{props.data.selectedConsigneeCompany ? props.data.selectedConsigneeCompany.name : '-'}</Text>
                </View>
                {props.data.consigneeText ? deltaToPDF(props.data.consigneeText, { gap: 7 }) : ''}
            </View>

            <View style={styles.mainText}>
                {props.data.mainText ? deltaToPDF(props.data.mainText, { gap: 2 }) : ''}
            </View>

            <View style={styles.sign}>
                <table style={{ width: "100%" }}>
                    <tr style={{ display: "flex", flexDirection: "row", gap: 120 }}>
                        <td style={{ width: "60%", justifyContent: "center" }}> </td>
                        <td style={{ width: "40%", justifyContent: "center", textAlign: "center", marginTop: 4, gap: 4 }}>
                            <Text>Hormat kami,</Text>
                            <Text> </Text>
                            <Text> </Text>
                            <Text> </Text>
                            <Text> </Text>
                            <Text style={props.data.selectedSignature ? { fontWeight: 700, textDecoration: 'underline' } : {}}>{props.data.selectedSignature ? props.data.selectedSignature.name : "____________________"}</Text>
                            <Text>{props.data.selectedSignature ? props.data.selectedSignature.position : ""}</Text>
                        </td>
                    </tr>
                </table>
            </View>
        </View>
    }

    return(
        <Page size="A4" style={{ fontFamily: "Arial" }}>
            {content}
        </Page>
    )
}

export default MailingPage
