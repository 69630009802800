import React from 'react'
import WaybillProducts from './WaybillProducts'
import axios from 'axios'
import { useGlobal } from '../../GlobalProvider'
import { arabToRoman } from 'roman-numbers'
import { Checkbox, FormControlLabel, Switch } from '@mui/material'
import { config } from '../../config'

const WaybillForm = (props) => {
    const [global, setGlobal] = useGlobal()

    const handleChange = async (e) => {
        if(e.target.name === 'date') {
            props.setData({
                ...props.data,
                [e.target.name]: new Date(e.target.value)
            })
        } else if(e.target.name === 'selectedConsigneeCompany' || 
                    e.target.name === 'selectedSignature') {
            props.setData({
                ...props.data,
                [e.target.name]: JSON.parse(e.target.value)
            })
        } else if(e.target.name === 'selectedShipperCompany') {
            const filterCodeResults = props.data.waybills.map(el => el.no).filter(el => {
                return el.includes(JSON.parse(e.target.value).code) && el.split('_')[0] === (props.data.type ? 'WI' : 'WO')
            })
            const filterYearResults = filterCodeResults.map(el => el.split('_')).filter(el => el[2] === new Date().getFullYear().toString())
            const filterNumberResults = filterYearResults.map(el => +el[el.length-1])
            const maxNumber = Math.max(...filterNumberResults)

            props.setData(d => {return {
                ...d,
                [e.target.name]: JSON.parse(e.target.value),
                no: maxNumber+1
            }})
        } else if(e.target.name === 'news') {
            props.setData({
                ...props.data,
                [e.target.name]: e.target.checked
            })
        } else if(e.target.name === 'type') {
            props.setData({
                ...props.data,
                [e.target.name]: e.target.checked
            })
        } else {
            props.setData({
                ...props.data,
                [e.target.name]: e.target.value
            })
        }
    }

    const handleAdd = () => {
        props.setData({
            ...props.data,
            products: [...props.data.products, {
                name: "",
                grossWeight: "",
                netWeight: ""
            }]
        })
    }

    const upload = async (formData) => {
        axios.post(`${config.API_HOST}/admin/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(res => {
                setGlobal({
                    ...global,
                    notif: {
                        warning: false,
                        message: res.data.message
                    }
                })
            })
            .catch(err => {
                if(err.response){
                    if(err.response.status === 403){
                        setGlobal({
                            ...global,
                            user: null,
                            notif: {
                                warning: true,
                                message: err.response.data.message
                            }
                        })
                    } else {
                        setGlobal({
                            ...global,
                            notif: {
                                warning: true,
                                message: err.response.data.message
                            }
                        })
                    } 
                } else {
                    setGlobal({
                        ...global,
                        notif: {
                            warning: true,
                            message: `${err.message}!`
                        }
                    })
                }
            })
    }

    const handleSubmit = () => {
        const content = document.querySelector('iframe')

        const fileName = `${props.data.type ? 'WI' : 'WO'}_${props.data.selectedShipperCompany ? props.data.selectedShipperCompany.code 
            : "xxx"}_${props.data.date.getFullYear().toString()}_${arabToRoman(props.data.date.getMonth() + 1)}_${props.data.no.toString().padStart(4, '0')}`

        fetch(content.src)
            .then(response => response.blob())
            .then(blob => {
                const file = new File([blob], 
                    `${fileName}.pdf`,
                    { type: 'application/pdf' })

                const formData = new FormData()
                formData.append('file', file)

                axios.post(`${config.API_HOST}/admin/waybills/add`, {
                    no: fileName,
                    shipper_company: props.data.selectedShipperCompany ? props.data.selectedShipperCompany.name : '',
                    consignee_company: props.data.selectedConsigneeCompany ? props.data.selectedConsigneeCompany.name : '',
                    products: JSON.stringify(props.data.products),
                    date: props.data.date.toISOString(),
                    location: props.data.location ? props.data.location : '',
                    driver: props.data.driverName ? props.data.driverName : '',
                    plate_number: props.data.plateNumber ? props.data.plateNumber : '',
                })
                    .then(res => {
                        setGlobal({
                            ...global,
                            notif: {
                                warning: false,
                                message: res.data.message
                            }
                        })

                        upload(formData)
                            .then(res => {
                                if(global.isMobile){
                                    const link = document.createElement('a')
                                    link.href = content.src
                                    link.setAttribute('download', file.name)
        
                                    document.body.appendChild(link)
                                    link.click()
        
                                    document.body.removeChild(link)
                                } else {
                                    content.contentWindow.print()
                                }
                            })
                    })
                    .catch(err => {
                        if(err.response){
                            if(err.response.status === 403){
                                setGlobal({
                                    ...global,
                                    user: null,
                                    notif: {
                                        warning: true,
                                        message: err.response.data.message
                                    }
                                })
                            } else {
                                setGlobal({
                                    ...global,
                                    notif: {
                                        warning: true,
                                        message: err.response.data.message
                                    }
                                })
                            } 
                        } else {
                            setGlobal({
                                ...global,
                                notif: {
                                    warning: true,
                                    message: `${err.message}!`
                                }
                            })
                        }
                    })
            })
    }

    return (
        <div id='waybill-form'>
            <h2> Waybill (Surat Jalan) </h2>
            <table style={{ width: "60%" }}>
                <tbody>
                    <tr>
                        <td style={{width: "40%"}}> <label> No </label> </td>
                        <td> <input type="text" value={props.data.no ? props.data.no : 1} style={{ width: 40, textAlign: "right" }} onChange={handleChange} name="no" autoComplete="off" 
                                pattern="[0-9]*" 
                                inputMode="numeric" 
                                maxLength="4" /> </td>
                    </tr>
                    <tr>
                        <td colSpan={2}> <FormControlLabel control={<Switch name="type" onClick={handleChange} />} label='Waybill Keluar / Masuk' /> </td>
                    </tr>
                    <tr>
                        <td> <label> Date </label> </td>
                        <td> <input type="date" value={props.data.date ? props.data.date.toISOString().slice(0, 10) : new Date()} style={{ textAlign: "right" }} onChange={handleChange} name="date" /> </td>
                    </tr>
                    <tr>
                        <td> <label> Nomor PO </label> </td>
                        <td> <input type="text" defaultValue={props.data.po} style={{ width: "100%" }} onChange={handleChange} name="po" /> </td>
                    </tr>
                    <tr>
                        <td> <label> Perusahaan Shipper </label> </td>
                        <td>
                            <select value={props.data.selectedShipperCompany ? JSON.stringify(props.data.selectedShipperCompany) : ""} onChange={handleChange} name="selectedShipperCompany" style={{ width: "100%" }}>
                                <option style={{ textAlign: 'center' }} disabled value={""}> -- Silahkan Pilih -- </option>
                                {props.data.companies.map((el, index) => {
                                    if(el.name.includes('GOMAS')){
                                        return <option key={index} value={JSON.stringify(el)}>{el.name}</option>
                                    }
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td> <label> Perusahaan Consignee </label> </td>
                        <td>
                            <select value={props.data.selectedConsigneeCompany ? JSON.stringify(props.data.selectedConsigneeCompany) : ""} onChange={handleChange} name="selectedConsigneeCompany" style={{ width: "100%" }}>
                                <option style={{ textAlign: 'center' }} disabled value={""}> -- Silahkan Pilih -- </option>
                                {props.data.companies.map((el, index) => 
                                    <option key={index} value={JSON.stringify(el)}>{el.name}</option>
                                )}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td> <label> Yang Menandatangani </label> </td>
                        <td>
                            <select value={props.data.selectedSignature ? JSON.stringify(props.data.selectedSignature) : ""} onChange={handleChange} name="selectedSignature" style={{ width: "100%" }}>
                                <option style={{ textAlign: 'center' }} value={JSON.stringify(null)}> -- Silahkan Pilih -- </option>
                                {props.data.signatures.map((el, index) => 
                                    <option key={index} value={JSON.stringify(el)}>{el.name}</option>
                                )}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td> <label> Lokasi Asal </label> </td>
                        <td> <input value={props.data.location ? props.data.location : ""} type="text" style={{ width: "100%" }} onChange={handleChange} name="location" /> </td>
                    </tr>
                    <tr>
                        <td> <label> Nama Driver </label> </td>
                        <td> <input value={props.data.driverName ? props.data.driverName : ""} type="text" style={{ width: "100%" }} onChange={handleChange} name="driverName" /> </td>
                    </tr>
                    <tr>
                        <td> <label> Plat Nomor </label> </td>
                        <td> <input value={props.data.plateNumber ? props.data.plateNumber : ""} type="text" style={{ width: "100%" }} onChange={handleChange} name="plateNumber" /> </td>
                    </tr>
                    <tr>
                        <td colSpan={2}> <FormControlLabel control={<Checkbox  name="news" onClick={handleChange} />} label="Dengan Berita Acara Untuk Cangkang Sawit" /> </td>
                    </tr>
                    <tr>
                        <td> <label> Nama PKS </label> </td>
                        <td> <input type="text" style={{ width: "100%" }} onChange={handleChange} name="pks" value={props.data.pks} /> </td>
                    </tr>
                </tbody>
            </table>

            <table style={{ width: "60%", marginTop: 12 }}>
                <thead>
                    <tr>
                        <th>Produk</th>
                        <th>Berat Kotor (G.W.)</th>
                        <th>Berat Tare (T.W.)</th>
                        <th>Berat Bersih (N.W.)</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.products.map((el, index) => (
                        <WaybillProducts key={index} data={props.data} setData={props.setData} el={el} index={index}/>
                    ))}
                    <tr>
                        <td colSpan={5}> <button style={{ width: "100%" }} onClick={handleAdd}>+</button> </td>
                    </tr>
                </tbody>
            </table>

            <br/>

            <button style={{ width: 100, height: 36 }} onClick={handleSubmit}> Upload </button>
            <button style={{ width: 100, height: 36 }} onClick={() => {console.log(props.data)}}> Test </button>
        </div>
    )
}

export default WaybillForm
