import React from 'react'

const Home = () => {
    return(
        <div>
            
        </div>
    )
}

export default Home
